import {createApi} from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery} from "@reduxjs/toolkit/query";
import { products } from "../types/TypeProducts";

const _baseUrl = process.env.REACT_APP_BACKEND_URL
export const productsApi = createApi({
    reducerPath: 'productsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: _baseUrl
    }),
    endpoints: (builder) => ({
        getProducts: builder.query({
            query: (data) => ({
                url: `commodity/?category__name=${data.categoryName}&page=${data.currentPage}&page_size=${data.size}`,
                method: 'GET'
            })
        }),
        getProduct: builder.query({
            query: (id: number) => ({
                url: `commodity/${id}`,
                method: 'GET'
            })
        }),
        getCategories: builder.query({
            query: () => ({
                url: 'category',
                method: 'GET'
            })
        }),
        sendOrder: builder.mutation({
            query: (data: any) => ({
                url: 'order/',
                method: 'POST',
                body: data
            })
        })
    })
})