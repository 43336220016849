import React, {useEffect, useState} from 'react';
import { Routes, Route } from "react-router-dom";
import {ProductList} from "./pages/ProductList";
import Cookies from "js-cookie";
import {ProductPage} from "./pages/ProductPage";
import {Basket} from "./pages/Basket";
import {Order} from "./pages/Order";
import {SuccessPage} from "./pages/SuccessPage";
export const App = () =>  {

    const [basket, setBasket] = useState([])

    useEffect(() => {
        try {
            const cookie: any = Cookies.get("order_basket_vsp_su3")
            setBasket(JSON.parse(cookie))
        } catch {
            console.log()
        }
    }, [])

    return (
        <Routes>
            <Route path={"/"} element={<ProductList basket={basket} setBasket={setBasket}/>}/>
            <Route path={"/product/:id"} element={<ProductPage basket={basket} setBasket={setBasket}/>}/>
            <Route path={"/basket"} element={<Basket basket={basket} setBasket={setBasket}/>}/>
            <Route path={"/order"} element={<Order basket={basket} setBasket={setBasket}/>}/>
            <Route path={"/success"} element={<SuccessPage/>}/>
        </Routes>
    );
}

