import React, { useEffect, useState } from 'react';
import * as PUI from "../pages/style";
import {IBasket, IItem, IItemInBasket, IProduct} from "../pages/ProductList";
import {productFilter, productFind} from "../features/customFunctions";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import {useTelegram} from "../hooks/useTelegram";
import {ImageProduct} from "./ImageProduct";

interface IProductItem extends IBasket {
    item: IItem,
    removeFromBasket: any
}
export const ProductItem = ({basket, setBasket, item, removeFromBasket}: IProductItem) => {

    const { tg } = useTelegram()
    const navigate = useNavigate()
    const [currentIndex, setCurrentIndex] = useState<number>(0)
    const addInBasket = (item: IItem) => {
        const findItemIndex = basket.findIndex((product: IItemInBasket) => product.id === item.id)
        const count = basket[findItemIndex]?.amount || 0
        const newItem = {
            id: item.id,
            amount: count+1,
            image_file: item.images[0].image_file,
            price: item.price,
            name: item.name
        }
        const basketWithoutItem = basket.filter((product: IItemInBasket) => product.id !== item.id)
        setBasket([...basketWithoutItem, newItem])
        Cookies.set("order_basket_vsp_su3", JSON.stringify([...basketWithoutItem, newItem]), {expires: 3})
        // setBasket((prevState: IItem[]) => [...prevState, {item.id}])
    }

    useEffect(() => {
        if (item.images.length !== 0) {
            setInterval(() => {
                // setCurrentIndex(prevState => prevState + 1)
                // :
                // setCurrentIndex(0)
                setCurrentIndex(prevState => {
                    if (prevState !== item.images.length - 1) {
                        return prevState + 1
                    } else {
                        return 0
                    }
                })
            }, 5000)
        }
    }, []);

    return (
        <PUI.Item>
            <div style={{width: "100%", height: "100%"}}>
                <ImageProduct item={item}/>
            </div>
            {/*<h3>{JSON.stringify(item.images[0].image_file)}</h3>*/}
            <h4>{item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽</h4>
            <h3 onClick={() => navigate(`/product/${item.id}`)}>{item.name}</h3>
            {/*<h5>{item.category}</h5>*/}
            <PUI.ProductButtons theme={tg?.themeParams}>
                {productFind(basket, item)?.amount ?
                    <PUI.CustomButton type={"button"}
                                      theme={tg?.themeParams}
                                           onClick={() => removeFromBasket(item)}
                    ><img
                        src={`/images/${tg?.themeParams.text_color === '#ffffff' ? 
                        'minus_black.svg' : 'minus_white.svg' ? 'minus_white.svg' : 'minus_black.svg'}`} alt={'-'}/>
                    </PUI.CustomButton>
                    :
                    <></>
                }
                {productFind(basket, item)?.amount ?
                    <span>
                {productFind(basket, item)?.amount}
            </span>
                :
                <></>
                }
                {productFind(basket, item)?.amount ?
                    <PUI.CustomButton type={"button"}
                                      theme={tg?.themeParams}
                                           onClick={() => addInBasket(item)}>
                        <img src={`/images/${tg?.themeParams.text_color === '#ffffff' ?
                            'plus_black.svg' : 'plus_white.svg' ? 'plus_white.svg' : 'plus_black.svg'}`} alt={'+'}/>
                    </PUI.CustomButton>
                :
                    <PUI.ButtonAddInBasket type={"button"}
                                           theme={tg?.themeParams}
                                           onClick={() => addInBasket(item)}>
                        Добавить
                    </PUI.ButtonAddInBasket>}
            </PUI.ProductButtons>
        </PUI.Item>
    )
}
