import React, {useCallback, useEffect, useState} from 'react';
import * as PUI from "./style";
import {IBasket, IItem, IItemInBasket} from "./ProductList";
import { useNavigate } from "react-router-dom";
import {productFilter, productFindInBasket} from "../features/customFunctions";
import Cookies from "js-cookie";
import {useTelegram} from "../hooks/useTelegram";

export interface IProduct {
    product: IItem,
    count: number,
}
export const Basket = ({basket, setBasket}: IBasket) => {
    const { tg } = useTelegram()
    const navigate = useNavigate()
    const [ products, setProducts ] = useState<IProduct[]>([])
    const [ totalPrice, setTotalPrice ] = useState<number>(0)
    //
    // useEffect(() => {
    //     const result: IProduct[] = []
    //     basket.map((item: IItemInBasket, index: number) => {
    //         if (!productFindInBasket(result, item)) {
    //             result.push({
    //                 product: item,
    //                 count: 1
    //             })
    //         } else {
    //             const index = result.findIndex((product: IProduct) =>
    //                 JSON.stringify(product.product) === JSON.stringify(item))
    //             result[index].count = result[index].count + 1
    //         }
    //     })
    //     setProducts(result)
    // }, [basket])
    useEffect(() => {
        let total = 0;
        basket.map((product: IItemInBasket) => {
            total += product.amount * product.price
        })
        setTotalPrice(+total.toFixed(2))
        Cookies.set('total_amount_vsp_su', total.toFixed(2).toString())
    }, [basket])

    useEffect(() => {
        if(totalPrice > 0) {
            tg.MainButton.color = tg.themeParams.text_color
            tg.MainButton.textColor = tg.themeParams.text_color === '#ffffff' ? '#000' : '#ffffff'
            tg.MainButton.show()
            tg.MainButton.isActive = true
            tg.MainButton.text = 'Продолжить'
        } else {
            tg.MainButton.hide()
        }
    }, [basket, totalPrice])

    const clickMainButton = useCallback(() => {
        navigate('/order')
    }, [])

    useEffect(() => {
        tg.onEvent('mainButtonClicked', clickMainButton)
        return () => {
            tg.offEvent('mainButtonClicked', clickMainButton)
        }
    }, [])
    const removeFromProducts = (item: IItemInBasket) => {
        const res = basket
            .filter((product: IItemInBasket) => product.id !== item.id)
        console.log('res', res)
        setBasket(res)
        Cookies.set("order_basket_vsp_su3", JSON.stringify(res), { expires : 3 })
    }

    return (
        <PUI.MainBlockBasket theme={tg?.themeParams}>
            <PUI.BasketPage>
                <PUI.TopPanelPageItem>
                    <button type={"button"} onClick={() => navigate("/")}>
                        Назад
                    </button>
                </PUI.TopPanelPageItem>
                {basket.length > 0 ?
                    <PUI.ProductsList>
                        {basket.map((item:any, index) => {
                            return (
                                <PUI.Product key={index} theme={tg?.themeParams}>
                                    <PUI.ProductElem>
                                        <img src={item.image_file || '/images/z.png'}
                                             alt={"image"}/>
                                        <div>
                                            <h4>{item.name},&nbsp;
                                                {item.amount} шт.
                                            </h4>
                                            {/*<h5>{item.product.category}</h5>*/}
                                            {/*<h5>{item.product.description}</h5>*/}
                                        </div>
                                    </PUI.ProductElem>
                                    <PUI.ProductElem style={{marginRight: "10px"}}>
                                        <h5 style={{width: "70px", whiteSpace: "nowrap"}}>
                                            {Math.ceil(item.amount * item.price)
                                                .toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}&nbsp;₽
                                        </h5>
                                        <button type={"button"}
                                                onClick={() => removeFromProducts(item)}>
                                            <img
                                                src={`/images/${tg?.themeParams.text_color === '#ffffff' ? 'remove_white.webp' : 'remove_black.svg'}`}
                                                 alt={"image"}/>
                                        </button>
                                    </PUI.ProductElem>
                                </PUI.Product>
                            )
                        })}
                    </PUI.ProductsList>
                :
                <h1>У вас нет товаров в корзине</h1>}
                {basket.length > 0 && <PUI.TotalAmount theme={tg?.themeParams}
                    style={{ marginLeft: "10px", marginTop: "20px"}}>
                    Итого: {totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽</PUI.TotalAmount>}
            </PUI.BasketPage>
        </PUI.MainBlockBasket>
    )
}