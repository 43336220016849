import styled from "styled-components";

const colorGreen = `rgba(26, 255, 0, 0.9)`

export const ListItems = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 15px;
  column-gap: 15px;
  margin: 10px;
  height: 85vh;
  overflow-y: scroll;
  @media(max-width: 1240px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media(max-width: 940px) {
    grid-template-columns: 1fr 1fr;
  }
`

export const LoaderBlock = styled.div`
  width: 100% !important;
  max-width: 100% !important;
  height: 170px;
  display: flex;
  overflow: hidden;
  padding-bottom: 20px;
`
export const ProductImage = styled.img`
  width: 100%;
  border-radius: 6px;
  height: 165px;
`
export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  border-radius: 24px;
  box-sizing: content-box;
  padding: 10px;
  img{
    width: 100%;
    border-radius: 6px;
    :hover{
      cursor: pointer;
    }
  }
  h3{
    margin-top: 4px;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 123.077% */
    letter-spacing: -0.4px;
  }
  h4{
    margin-top: 8px;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 123.077% */
    letter-spacing: -0.4px;
  }
  div{
    :hover{
      cursor: pointer;
    }
  }
  h3:hover{
    cursor: pointer;
  }
`

export const Gallery = styled.div<{theme: any}>`
  display: flex;
  width: 100%;
  overflow-y: scroll;
  margin-top: 10px;
  div{
    object-fit: cover;
    img, video, source{
      width: 100% !important;
      height: 100% !important;
      max-width: 80px !important;
      max-height: 80px !important;
      margin: 5px;
      border: 1px solid ${(props: any) => props.theme.text_color || 'black'} !important;
      border-radius: 6px;
      object-fit: cover;
      padding: 5px;
      :hover{
        cursor: pointer;
      }
    }
  }
`
export const ProductButtons = styled.div<{theme: any}>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 8px;
  span{
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 123.077% */
    letter-spacing: -0.4px;
    color: ${(props: any) => props.theme.text_color || 'black'} !important;
  }
`

export const ButtonAddInBasket = styled.button<{theme: any}>`
  width: 100%;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  padding: 6px 10px;
  background: ${(props: any) => props.theme.text_color === '#ffffff' ? 'white' : 'black'} !important;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 123.077% */
  letter-spacing: -0.4px;
  color: ${(props: any) => props.theme.text_color === '#ffffff' ? 'black' : 'white' ? 'white' : 'black'} !important;
  img{
    width: 14px !important;
    height: 14px !important;
  }
  :hover{
    cursor: pointer;
  }
`

export const CustomButton = styled(ButtonAddInBasket)`
  width: 52.5px;
`

export const CategoriesBlock = styled.ul<{selected: number, theme: any}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  margin-top: 10px;
  li{
    list-style-type: none;
    margin: 5px 3px;
    white-space: nowrap;
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 1px solid ${(props: any) => props.theme.text_color === '#ffffff' ? '#C6C7D7' : '#5C678C'};
    color:  ${(props: any) => props.theme.text_color === '#ffffff' ? '#C6C7D7' : '#5C678C'};
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px; /* 136.364% */
    letter-spacing: -0.4px;
    border-radius: 40px;
  }
  li:hover{
    cursor: pointer;
  }
  li:nth-child(${(props: any) => props.selected}) {
    background: var(--main, #5B9D51);
    border: none;
    color: white !important;
  }

`


export const TopPanelBlock = styled.div<{theme: any}>`
  display: flex;
  flex-direction: column;
  margin: 10px;
  span{
    border: 1.5px solid ${(props: any) => props.theme.text_color || 'black'};;
    border-radius: 5px;
    display: inline-flex;
    padding: 6px 15px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    color: ${(props: any) => props.theme.text_color || 'black'} !important;
  }
  ${CategoriesBlock}{
    li{
      color: ${(props: any) => props.theme.text_color || 'black'};
    }
  }
`
export const MainBlock = styled.div<{theme: any}>`
  color: ${(props: any) => props.theme.text_color || 'black'};
  background: ${(props: any) => props.theme.bg_color || 'white'};
  h5{
    color: ${(props: any) => props.theme.hint_color || '#98989e'} !important;
  }
  ${ButtonAddInBasket} {
    border: 1px solid black;
  }
  font-family: "SF Pro Text", sans-serif !important;
`

export const MainBlockBasket = styled(MainBlock)<{theme: any}>`
  background: none;
  button{
    color: ${(props: any) => props.theme.text_color || 'black'};
  }
  h1{
    color: ${(props: any) => props.theme.text_color || 'black'};
  }
`


export const TopPanelPageItem = styled.div`
  display: flex;
  width: 100%;
  margin: 10px 0;
  align-items: center;
  justify-content: space-between;
  button{
    background: none;
    padding: 5px 10px;
    font-size: 18px;
    border-radius: 4px;
  }
`

export const ButtonBack = styled.button`
  border: none !important;
  background: none !important;
  position: sticky;
  float: right;
  display: flex;
  width: 100%;
  text-align: right;
  justify-content: end;
  font-size: 18px;
  font-weight: 600;
  color: ${(props: any) => props.theme.text_color || 'black'};
  :hover{
    cursor: pointer;
  }
`
export const ContentPageItem = styled.div`
  margin: 10px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  h2{
    margin: 10px 0;
  }
  p{
    font-weight: 500;
  }
`
export const PageItem = styled.div<{theme: any}>`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${TopPanelPageItem} {
    button{
      color: ${(props: any) => props.theme.text_color || 'black'};
      border: 1px solid ${(props: any) => props.theme.text_color || 'black'};
    }
    span{
      position: sticky;
      float: right;
      display: flex;
      width: 100%;
      text-align: right;
      justify-content: end;
      font-size: 18px;
      font-weight: 600;
      color: ${(props: any) => props.theme.text_color || 'black'};
    }
  }
  ${ContentPageItem} {
    h4{
      color: ${(props: any) => props.theme.hint_color || '#98989e'} !important;
    }
    p, h2{
      color: ${(props: any) => props.theme.text_color || 'black'} !important;
    }
  }
  video{
    width: 100% !important;
    height: 100% !important;
    max-height: 260px;
    object-fit: contain;
  }
`

export const MainImage = styled.img`
  width: 100% !important;
  height: 100% !important;
  max-height: 260px;
  object-fit: contain;
`

export const MainVideo = styled.div`
  object-fit: cover;
  height: 260px;
  video{
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
  }
`
export const BasketPage = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1{
    width: 100%;
    margin-bottom: 15px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 130% */
    letter-spacing: -0.4px;
  }
`

export const Form = styled.form<{theme: any}>`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 100%;
  overflow: hidden;
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    select:focus, textarea:focus, input:focus {
      font-size: 16px;
      outline: none;
    }
  }
  white-space: normal;
  word-wrap: break-word;
  color: ${(props: any) => props.theme.text_color || 'black'} !important;
  input, textarea{
    margin-bottom: 8px;
    height: 40px;
    border-radius: 6px;
    outline: none;
    border: 1px solid ${(props: any) => props.theme.text_color || 'black'} !important;
    padding-left: 10px;
    background: none;
    color: ${(props: any) => props.theme.text_color || 'black'} !important;
  }
  textarea::placeholder, input::placeholder{
    color:  ${(props: any) => props.theme.text_color === '#ffffff' ? '#C6C7D7' : '#5C678C'};
  }
  textarea{
    padding-top: 5px;
    height: 60px;
  }
`

export const ProductsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
`

export const ProductElem = styled.div`
  display: flex;
  align-items: center;
  h4{
    display: flex;
  }
  div{
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    align-items: start;
    white-space: normal;
    word-wrap: break-word;
    flex-wrap: wrap;
  }
  button{
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    img{
      width: 25px;
      height: 25px;
    }
  }
`

export const TotalAmount = styled.h3<{theme: any}>`
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 130% */
  letter-spacing: -0.4px;
  width: 100%;
  color: ${(props: any) => props.theme.text_color || 'black'} !important;
`
export const Product = styled.div<{theme: any}>`
  width: 100%;
  height: 73px;
  align-items: center;
  gap: 16px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #DEE2EF;
  img{
    border-radius: 4px;
    width: 42px;
    height: 42px;
  }
  div{
    display: flex;
    align-items: center;
  }
  ${ProductElem}{
    h4{
      color:  ${(props: any) => props.theme.text_color === '#ffffff' ? '#C6C7D7' : '#5C678C'};
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 123.077% */
      letter-spacing: -0.4px;
    }
    h5{
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 123.077% */
      letter-spacing: -0.4px;
      color: ${(props: any) => props.theme.text_color || 'black'} !important;
    }
  }
`
export const ContentContainer = styled.div<{theme: any}>`
  width: 100%;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-variant: small-caps;
  button{
    background: white;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 123.077% */
    letter-spacing: -0.4px;
    display: flex;
    width: 165px;
    padding: 6px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 1.5px solid ${(props: any) => props.theme.text_color || 'black'} !important;
    border-radius: 5px;
    color: ${(props: any) => props.theme.text_color === '#ffffff' ? 'black' : 'white' ? 'white' : 'black'} !important;
    background: ${(props: any) => props.theme.text_color === '#ffffff' ? 'white' : 'black'} !important;
  }
`

export const PaginateContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
  align-content: start;
  margin: 3px;
`

export const PageButton = styled.button<{current: number}>`
  margin: 2px;
  padding: 0 5px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 0.9em;
  border: 2px solid ${colorGreen};
  border-radius: 100%;
  transition: ease 0.5s;
  background: white;
  :hover{
    cursor: pointer;
    background: ${colorGreen};
    color: white;
  }
  :nth-child(${(props: any) => +props.current}) {
    background: ${colorGreen};
    color: white;
  }
`

export const ShowMore = styled.button`
  margin: 2px;
  padding: 0 5px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 0.9em;
  border: 2px solid ${colorGreen};
  border-radius: 100%;
  transition: ease 0.5s;
  background: white;
`