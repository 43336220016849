import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import * as PUI from "./style";
import {useTelegram} from "../hooks/useTelegram";
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";

export const SuccessPage = () => {
    const { tg } = useTelegram()
    const navigate = useNavigate()

    const closeApp = () => {
        tg.close()
    }

    useLayoutEffect(() => {
        tg.onEvent('mainButtonClicked', closeApp)
        return () => {
            tg.offEvent('mainButtonClicked', closeApp)
        }
    }, [closeApp])
    useLayoutEffect(() => {
        tg.MainButton.show()
        tg.MainButton.color = tg.themeParams.text_color
        tg.MainButton.textColor = tg.themeParams.text_color === '#ffffff' ? '#000' : '#ffffff'
        tg.MainButton.isActive = true
        tg.MainButton.text = 'Закрыть'
    }, [])
    return (
        <PUI.MainBlockBasket theme={tg.themeParams}>
            <PUI.BasketPage>
                <h1>Заказ принят. Ожидайте, с вами свяжется менеджер.</h1>
            </PUI.BasketPage>
        </PUI.MainBlockBasket>
    )
}