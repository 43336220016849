import {IItem, IItemInBasket} from "../pages/ProductList";
import { IProduct } from "../pages/ProductList";
export const numberToArray = (size: number) => {
    const numberInArray: number[] = []
    for (let i: number = size; i !== 0; i--) {
        numberInArray.unshift(i)
    }
    return numberInArray
}

export const productFind = (basket: IItemInBasket[], item: IItem) => {
    return basket.find((product: IItemInBasket) => product.id === item.id)
}
export const productFilter = (basket: IItemInBasket[], item: IItem) => {
    return basket.filter((product: IItemInBasket) => product.id === item.id)
}

export const productFindInBasket = (basket: IItemInBasket[], item: IItem) => {
    return basket.find((product: IItemInBasket) =>
        product.id === item.id)
}

export const checkFormat = (url: string) => {
    const photoFormats = ['jpeg', 'png', 'jpg', 'webp']
    const checkType = url?.lastIndexOf('.')
    const resultType = url?.slice(checkType + 1)
    return photoFormats.includes(resultType)  ? 'image' : 'video'
}