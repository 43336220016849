import React, {useState} from "react";
import * as PUI from "../pages/style";
import {useNavigate} from "react-router-dom";

export const ImageProduct = ({item}: any) => {
    const [ loading, setLoading ] = useState<boolean>(false)
    const navigate = useNavigate()
    return (
        <PUI.ProductImage src={loading ? item.images[0]?.image_file : '/images/z.png'}
                          alt={'Error'}
                          onLoad={() => setLoading(true)}
                          onClick={() => navigate(`/product/${item.id}`)}
        />
    )
}