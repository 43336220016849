import React, {useCallback, useEffect, useId, useState} from 'react';
import * as PUI from "./style";
import {IBasket, IItemInBasket} from "./ProductList";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {useTelegram} from "../hooks/useTelegram";
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import 'react-phone-number-input/style.css'
import Cookies from "js-cookie";
import {productsApi} from "../services/products";
import {Spinner} from "../components/Spinner";
export const Order = ({basket, setBasket}: IBasket) => {
    const navigate = useNavigate()
    const { tg } = useTelegram()

    const products = Cookies.get("order_basket_vsp_su3") || ''
    const total = Cookies.get("total_amount_vsp_su")
    const { register, handleSubmit, control, formState: { errors }, setValue} = useForm()
    const formId = useId()
    const [ state, setState ] = useState<any>()

    const [ loading, setLoading ] = useState<boolean>(false)
    const styleNumber = {
        marginLeft: "10px",
    }
    const inputStyle = {
        color: `${tg?.themeParams.text_color === '#ffffff' ? '#C6C7D7' : '#5C678C'}`
    }
    const testDataUser = {
        tg_id: 858301823,
        tg_username: "voitenkodev",
        tg_name: "Bogdan",
        tg_lastname: "Voitenko",
        ts_language: "en"
    }
    const [ sendOrder ] = productsApi.useSendOrderMutation()

    const user = {
        tg_id: tg?.initDataUnsafe?.user?.id || 'None',
        tg_username: tg?.initDataUnsafe?.user?.username || 'None',
        tg_name: tg?.initDataUnsafe?.user?.first_name || 'None',
        tg_lastname: tg?.initDataUnsafe?.user?.last_name || 'None',
        tg_language: tg?.initDataUnsafe?.user?.language_code || 'None',
    }

    const clickMainButton = useCallback( async (data: any) => {
        const basketForOrder: any = []
        for (let product of JSON.parse(products)) {
            if (product.amount > 0) {
                basketForOrder.push({
                    id: product.id,
                    amount: product.amount
                })
            }
        }
        const order = {
            userData: user,
            cartData: basketForOrder,
            deliveryData: data,
            value: total,
        }
        setLoading(true)
        await sendOrder(order).then((r: any) => {
            if (r.error) {
                setState(JSON.stringify(r))
            } else {
                setValue('name', '')
                Cookies.remove('order_basket_vsp_su3')
                navigate('/success')
            }
        }).then(() => setLoading(false))
    }, [])

    useEffect(() => {
        tg.onEvent('mainButtonClicked', handleSubmit(clickMainButton))
        return () => {
            tg.offEvent('mainButtonClicked', clickMainButton)
        }
    }, [clickMainButton])

    useEffect(() => {
        tg.MainButton.show()
        tg.MainButton.color = tg.themeParams.text_color
        tg.MainButton.textColor = tg.themeParams.text_color === '#ffffff' ? '#000' : '#ffffff'
        tg.MainButton.isActive = true
        tg.MainButton.text = 'Отправить заявку'
    }, [basket])
    return (
        <PUI.MainBlockBasket theme={tg.themeParams}>
            <PUI.BasketPage>
                {loading ?
                <Spinner/>
                :
                    <>
                        <PUI.TopPanelPageItem>
                            <button type={"button"} onClick={() => navigate("/")}>
                                Назад
                            </button>
                        </PUI.TopPanelPageItem>
                        <h1>Оформление заказа</h1>
                        <PUI.Form theme={tg?.themeParams} id={formId} onSubmit={handleSubmit(clickMainButton)}>
                            <PhoneInputWithCountry
                                limitMaxLength={true}
                                maxLength={"16"}
                                name="number"
                                control={control}
                                defaultCountry={"RU"}
                                keyboardType="phone-pad"
                                defaultValue={"+7"}
                                style={styleNumber}
                                textInputStyle={inputStyle}
                                rules={{ required: true, minLength: 7, maxLength: 20 }} />
                            {errors.number && <span
                                style={{color: "red", marginTop: "-8px", fontSize: "12px", marginBottom: "5px"}}>
                        Необходимо заполнить</span>}
                            <input type={"text"} {...register('name', { minLength: 10, required: true })} placeholder={'ФИО'}/>
                            {errors.name && <span
                                style={{color: "red", marginTop: "-8px", fontSize: "12px", marginBottom: "5px"}}>
                        Необходимо заполнить</span>}
                            <input type={"email"} {...register('email', { minLength: 5, required: true })} placeholder={'Почта'}/>
                            {errors.email && <span
                                style={{color: "red", marginTop: "-8px", fontSize: "12px", marginBottom: "5px"}}>
                        Необходимо заполнить</span>}
                            <textarea {...register('comment')} placeholder={'Комментарий'}/>
                            {/*<input type={"submit"} value={'go'}/>*/}
                        </PUI.Form>
                        {state && <h1>Error: {JSON.stringify(state)}</h1>}
                    </>
                }
            </PUI.BasketPage>
        </PUI.MainBlockBasket>
    )
}