import React, {useCallback, useEffect, useRef, useState} from 'react';
import * as PUI from "./style";
import {useTelegram} from "../hooks/useTelegram";
import {ProductItem} from "../components/ProductItem";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import {productsApi} from "../services/products";
import {find} from "styled-components/test-utils";
import {LittleSpinner, Spinner} from "../components/Spinner";

export interface IBasket {
    basket: IItemInBasket[]
    setBasket: any
}

export interface IItemInBasket {
    id: number,
    amount: number,
    image_file?: string,
    price: number,
    name: string
}
export interface IProduct {
    product: IItem,
    count: number,
}
export interface IItem {
    id: number | string
    name: string,
    description: string,
    images: image[],
    price: number,
    count: number,
    stats: any,
    size: string,
    category: string
}
export interface image {
    image_file: string,
    id:  number
}


export const ProductList = ({basket, setBasket}: IBasket) => {
    const { tg } = useTelegram()

    const [ selected, setSelected ] = useState(0)
    const [ count, setCount ] = useState(0)
    const navigate = useNavigate()
    const [ categoryName, setCategoryName ] = useState('')
    const [ currentPage, setCurrentPage ] = useState(1) // Need ADD CURRENT Page
    const [ size, setSize ] = useState(6)
    const [ pages, setPages ] = useState(0)
    const [ loadingMore, setLoadingMore ] = useState(false)
    const { data: products, isFetching, isError, refetch } = productsApi.useGetProductsQuery({categoryName, currentPage, size}, {
        refetchOnMountOrArgChange: true
    })

    const { data: categories, isLoading } = productsApi.useGetCategoriesQuery(undefined, {
        refetchOnMountOrArgChange: true
    })
    const removeFromBasket = (item: IItem) => {
        const findItemIndex = basket.findIndex((product: IItemInBasket) => product.id === item.id)
        const count = basket[findItemIndex]?.amount || 0
        const newItem = {
            id: item.id,
            amount: count-1
        }
        const basketWithoutItem = basket.filter((product: IItemInBasket) => product.id !== item.id)
        if (newItem.amount === 0) {
            setBasket(basketWithoutItem)
        } else {
            setBasket([...basketWithoutItem, newItem])
        }
    }
    const clickMainButton = useCallback(() => {
        navigate('/basket')
    }, [])
    useEffect(() => {
        tg.onEvent('mainButtonClicked', clickMainButton)
        return () => {
            tg.offEvent('mainButtonClicked', clickMainButton)
        }
    }, [])

    useEffect(() => {
        let count = 0;
        for (let item of basket) {
            count += item.amount
        }
        setCount(count)
    }, [basket]);
    const myRef = useRef(null)
    const showMore = () => {
        setLoadingMore(true)
        if (products?.count > size) setSize((prevState: number) => prevState + 6)
    }
    useEffect(() => {
        if (basket.length > 0) {
            tg.MainButton.color = tg.themeParams.text_color
            tg.MainButton.textColor = tg.themeParams.text_color === '#ffffff' ? '#000' : '#ffffff'
            tg.MainButton.isActive = true
            tg.MainButton.text = 'Оформить заказ'
            tg.MainButton.show()
        }else {
            tg.MainButton.hide()
        }
    }, [ basket ])

    useEffect(() => {
        if (!isFetching) {
            setPages(Math.ceil(products.count / size))
        }
    }, [isFetching]);

    useEffect(() => {
        if (!isFetching) {
            if (products?.count > size) setLoadingMore(false)
        }
    }, [isFetching]);
    const [ showEscape, setShowEscape ] = useState<boolean>(false)
    const [ showEnd, setShowEnd ] = useState<boolean>(false)

    // First paginate array (start)
    const [ paginateInArray, setPaginateInArray ] = useState<number[]>([0])

    // Second paginate array (middle)
    const [ middlePaginateArray, setMiddlePaginateArray ] = useState<number[]>([0])

    // Third paginate array (end)
    const [ endPaginateInArray, setEndPaginateInArray ] = useState<number[]>([0])

    // END PROPS

    // edit background color for currentPage
    const [ activePage, setActivePage ] = useState<number>(currentPage)

    useEffect(() => {
        if (pages > 0) {
            const pagesInArray: number[] = []
            for (let i: number = pages; i !== 0; i--) {
                pagesInArray.unshift(i)
            }
            if (pagesInArray.length <= 9) {
                setPaginateInArray(pagesInArray)
                setShowEscape(false)
            } else {
                setPaginateInArray(pagesInArray.slice(0, 3))
                setMiddlePaginateArray(pagesInArray.slice(3, 6))
                setEndPaginateInArray(pagesInArray.slice(pagesInArray.length - 1))
                setShowEscape(true)
                setShowEnd(true)
            }
        }
    }, [pages])
    const getLeft = () => {
        if (middlePaginateArray.length !== 3) {
            const newMiddleArray :number[] = []
            middlePaginateArray.map((page: number) => newMiddleArray.push(page - 3))
            newMiddleArray.push(newMiddleArray[0] + 1)
            newMiddleArray[2] = newMiddleArray[1] + 1
            setMiddlePaginateArray(newMiddleArray)
        } else {
            if (!middlePaginateArray.includes(paginateInArray[2] + 3)) {
                const newArray: number[] = []
                middlePaginateArray.map((page: number) => newArray.push(page - 3))
                setMiddlePaginateArray(newArray)
            }
        }
        setActivePage(0)
    }
    const getRight = () => {
        if (middlePaginateArray.includes(endPaginateInArray[0] - 3)) {
            if (middlePaginateArray[middlePaginateArray.length -1] + 1 === endPaginateInArray[0]){

            } else {
                const newMiddleArray: number[] = []
                middlePaginateArray.map((page: number) => newMiddleArray.push(page +3))
                const indexInMiddle = newMiddleArray.findIndex((item: number) => item === endPaginateInArray[0])
                setMiddlePaginateArray(newMiddleArray.slice(0, indexInMiddle))
            }
        } else {
            if (middlePaginateArray.length === 1 || middlePaginateArray.length === 2) {

            } else {
                const newArray: number[] = []
                middlePaginateArray.map((page: number) => newArray.push(page + 3))
                setMiddlePaginateArray(newArray)
            }
        }
        setActivePage(0)
    }

    const handleScroll = (event: any) => {
        event.preventDefault()
        console.log((event.target.scrollTop + event.target.offsetHeight + 10), event.target.scrollHeight)
        if (!loadingMore) {
            if ((event.target.scrollTop + event.target.offsetHeight + 10) > event.target.scrollHeight) {
                showMore(); //API method
            }
        }
    };

    if (isLoading) return <Spinner/>

    return (
        <PUI.MainBlock theme={tg?.themeParams}>
            {tg?.first_name ? <h1>Добро пожаловать, {tg?.first_name || tg?.username}</h1> : ''}
            {/*<h2>{JSON.stringify(tg)}</h2>*/}
            <PUI.TopPanelBlock theme={tg?.themeParams}>
                <PUI.ButtonBack type={"button"} onClick={() => navigate('/basket')}>
                    <span>
                        <img
                            src={`/images/${tg?.themeParams.text_color === '#ffffff' ? 'mdi_cart_white.svg' : 'mdi_cart.svg'}`}
                            alt={"basket"}/> Корзина ({count})</span>
                </PUI.ButtonBack>
                <PUI.CategoriesBlock selected={selected + 1} theme={tg?.themeParams}>
                    <li
                        onClick={() => {
                            setSelected(0)
                            setCategoryName('')
                        }}>Все</li>
                    {categories && categories?.results.map((item: any, index: number) => {
                        return (
                            <li key={index}
                                onClick={() => {
                                    setSelected(index+1)
                                    setCategoryName(item.name)
                                }}>{item.name}</li>
                        )
                    })}
                </PUI.CategoriesBlock>
            </PUI.TopPanelBlock>
            <PUI.ListItems  ref={myRef} onScroll={(event) => handleScroll(event)}>
                {products && products?.results.map((item: IItem, index: number) => {
                    return (
                        <ProductItem basket={basket} setBasket={setBasket} item={item}
                                     key={index} removeFromBasket={removeFromBasket}/>
                    )
                })}
                {(loadingMore && products?.count > size) && <PUI.LoaderBlock>
                    <Spinner/>
                </PUI.LoaderBlock>}
            </PUI.ListItems>
            <PUI.ContentContainer theme={tg?.themeParams}>
                {/*{loadingMore && <Spinner/>}*/}
                {/*{products.count > size ? <button type={"button"}*/}
                {/*                                 style={{marginBottom: "20px"}}*/}
                {/*                                 onClick={() => showMore()}>*/}
                {/*    <span>Показать еще</span>*/}
                {/*</button>*/}
                {/*:*/}
                {/*<></>}*/}
                {/*<PUI.PaginateContainer>*/}
                {/*    {paginateInArray.map((item, index) => {*/}
                {/*        return (*/}
                {/*            <PUI.PageButton*/}
                {/*                current={activePage}*/}
                {/*                key={index}*/}
                {/*                type="button"*/}
                {/*                onClick={() => {setCurrentPage(index+1); setActivePage(index+1)}}*/}
                {/*            >{item}</PUI.PageButton>*/}
                {/*        )*/}
                {/*    })}*/}
                {/*    { showEscape ?*/}
                {/*        <>*/}
                {/*            { showEnd ?*/}
                {/*                <>*/}
                {/*                    <span></span>*/}
                {/*                    <PUI.PageButton current={0} onClick={() => getLeft()}>*/}
                {/*                        &lt;*/}
                {/*                    </PUI.PageButton>*/}
                {/*                    <span>...</span>*/}
                {/*                </>*/}
                {/*                :*/}
                {/*                <span></span>*/}
                {/*            }*/}
                {/*            {middlePaginateArray.map((item, index) => {*/}
                {/*                return (*/}
                {/*                    <PUI.PageButton*/}
                {/*                        current={activePage}*/}
                {/*                        key={index}*/}
                {/*                        type="button"*/}
                {/*                        onClick={() => {setCurrentPage(item - 1); setActivePage(index+7)}}*/}
                {/*                    >{item}</PUI.PageButton>*/}
                {/*                )*/}
                {/*            })}*/}
                {/*            { showEnd ?*/}
                {/*                <>*/}
                {/*                    <span>...</span>*/}
                {/*                    <PUI.PageButton current={0} onClick={() => getRight()}>*/}
                {/*                        &gt;*/}
                {/*                    </PUI.PageButton>*/}
                {/*                    {endPaginateInArray.map((item, index) => {*/}
                {/*                        return (*/}
                {/*                            <PUI.PageButton*/}
                {/*                                current={activePage}*/}
                {/*                                key={index}*/}
                {/*                                type="button"*/}
                {/*                                onClick={() => {setCurrentPage(item - 1); setActivePage(index + 12)}}*/}
                {/*                            >{item}</PUI.PageButton>*/}
                {/*                        )*/}
                {/*                    })}*/}
                {/*                </>*/}
                {/*                :*/}
                {/*                <span></span>*/}
                {/*            }*/}
                {/*        </>*/}
                {/*        :*/}
                {/*        <span></span>*/}
                {/*    }*/}
                {/*</PUI.PaginateContainer>*/}
            </PUI.ContentContainer>
        </PUI.MainBlock>
    )
}