import React, {useCallback, useEffect, useState} from 'react';
import * as PUI from "./style";
import {useNavigate, useParams} from "react-router-dom";
import {IBasket, IItem, IItemInBasket} from "./ProductList";
import {useTelegram} from "../hooks/useTelegram";
import {checkFormat, productFilter, productFind} from "../features/customFunctions";
import Cookies from "js-cookie";
import {productsApi} from "../services/products";
import {Spinner} from "../components/Spinner";

export const ProductPage = ({basket, setBasket}: IBasket) => {
    const { tg } = useTelegram()
    const params = useParams()
    const productId = params.id
    const navigate = useNavigate()
    // const item = products.find((elem: IItem) => elem.id.toString() === productId)
    const [ count, setCount ] = useState(0)
    // @ts-ignore
    const { data: item, isLoading } = productsApi.useGetProductQuery(productId)
    const [ media, setMedia ] = useState([])
    const [ currentMedia, setCurrentMedia ] = useState()
    const addInBasket = (item: IItem) => {
        const findItemIndex = basket.findIndex((product: IItemInBasket) => product.id === item.id)
        const count = basket[findItemIndex]?.amount || 0
        const newItem = {
            id: item.id,
            amount: count+1,
            image_file: item.images[0].image_file,
            price: item.price,
            name: item.name
        }
        const basketWithoutItem = basket.filter((product: IItemInBasket) => product.id !== item.id)
        setBasket([...basketWithoutItem, newItem])
        Cookies.set("order_basket_vsp_su3", JSON.stringify([...basketWithoutItem, newItem]), {expires: 3})
        // setBasket((prevState: IItem[]) => [...prevState, {item.id}])
    }
    const removeFromBasket = (item: IItem) => {
        const findItemIndex = basket.findIndex((product: IItemInBasket) => product.id === item.id)
        const count = basket[findItemIndex]?.amount || 0
        const newItem = {
            id: item.id,
            amount: count-1
        }
        const basketWithoutItem = basket.filter((product: IItemInBasket) => product.id !== item.id)
        if (newItem.amount === 0) {
            setBasket(basketWithoutItem)
        } else {
            setBasket([...basketWithoutItem, newItem])
        }
    }
    const clickMainButton = useCallback(() => {
        navigate('/order')
    }, [])
    useEffect(() => {
        let count = 0;
        for (let item of basket) {
            count += item.amount
        }
        setCount(count)
    }, [basket]);
    useEffect(() => {
        if(basket.length > 0) {
            tg.MainButton.color = tg.themeParams.text_color
            tg.MainButton.textColor = tg.themeParams.text_color === '#ffffff' ? '#000' : '#ffffff'
            tg.MainButton.show()
            tg.MainButton.isActive = true
            tg.MainButton.text = 'Продолжить'
        } else {
            tg.MainButton.hide()
        }
    }, [basket])
    useEffect(() => {
        tg.onEvent('mainButtonClicked', clickMainButton)
        return () => {
            tg.offEvent('mainButtonClicked', clickMainButton)
        }
    }, [])

    useEffect(() => {
        if (item) {
            setMedia(item.images.concat(item.videos))
            setCurrentMedia(item.images[0].image_file)
        }
    }, [item]);
    if (isLoading) return <Spinner/>


    return (
        <PUI.PageItem theme={tg?.themeParams}>
            <PUI.TopPanelPageItem style={{marginTop: "-10px"}}>
                <button type={"button"} onClick={() => navigate("/")}>
                    Назад
                </button>
                <PUI.TopPanelBlock theme={tg?.themeParams}  style={{marginRight: "-10px"}}>
                    <PUI.ButtonBack type={"button"} onClick={() => navigate('/basket')}>
                    <span>
                        <img
                            style={{width: "20px"}}
                            src={`/images/${tg?.themeParams.text_color === '#ffffff' ? 'mdi_cart_white.svg' : 'mdi_cart.svg'}`}
                            alt={"basket"}/> Корзина ({count})</span>
                    </PUI.ButtonBack>
                </PUI.TopPanelBlock>
            </PUI.TopPanelPageItem>

            {checkFormat(currentMedia || '') === 'image' ?
                <PUI.MainImage src={currentMedia || '/images/z.png'} alt={'image'}/>
            :
                <PUI.MainVideo>
                    <video autoPlay={true} controls={true} src={currentMedia} playsInline={true}/>
                </PUI.MainVideo>}
            <PUI.Gallery theme={tg?.themeParams}>
                {media && media.map((item: any, index: number) => {
                    return (
                        checkFormat(item?.image_file || item?.video_file) === 'image' ?
                            <div key={index}>
                                <img
                                     src={item.image_file} alt={'image'}
                                     onClick={() => setCurrentMedia(item?.image_file)}/>
                            </div>
                            :
                    <div key={index}>
                        <video onClick={() => setCurrentMedia(item?.video_file)} src={item?.video_file}
                               poster={item?.video_file}/>
                        {/*poster={`/images/${tg.themeParams.text_color === '#ffffff' ? 'run_white.svg' : 'run.svg'}`}*/}
                    </div>
                    )
                })}
            </PUI.Gallery>
            <PUI.ContentPageItem>
                <h4>{item?.category}</h4>
                <h2>{item?.name}</h2>
                {/*// @ts-ignore*/}
                <div dangerouslySetInnerHTML={{ __html: item.description}}/>
                <h2>{item?.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} р.</h2>
                <PUI.ProductButtons theme={tg?.themeParams} style={{justifyContent: 'start'}}>
                    {productFind(basket, item)?.amount ?
                        <PUI.CustomButton type={"button"}
                                          theme={tg?.themeParams}
                                          onClick={() => removeFromBasket(item)}
                        ><img
                            src={`/images/${tg?.themeParams.text_color === '#ffffff' ?
                                'minus_black.svg' : 'minus_white.svg' ? 'minus_white.svg' : 'minus_black.svg'}`} alt={'-'}/>
                        </PUI.CustomButton>
                        :
                        <></>
                    }
                    {productFind(basket, item)?.amount ?
                        <span style={{margin: "0 10px"}}>
                {productFind(basket, item)?.amount}
            </span>
                        :
                        <></>
                    }
                    {productFind(basket, item)?.amount ?
                        <PUI.CustomButton type={"button"}
                                          theme={tg?.themeParams}
                                          onClick={() => addInBasket(item)}>
                            <img src={`/images/${tg?.themeParams.text_color === '#ffffff' ?
                                'plus_black.svg' : 'plus_white.svg' ? 'plus_white.svg' : 'plus_black.svg'}`} alt={'+'}/>
                        </PUI.CustomButton>
                        :
                        <PUI.ButtonAddInBasket type={"button"}
                                               style={{maxWidth: "120px"}}
                                               theme={tg?.themeParams}
                                               onClick={() => addInBasket(item)}>
                            Добавить
                        </PUI.ButtonAddInBasket>}
                </PUI.ProductButtons>
            </PUI.ContentPageItem>
        </PUI.PageItem>
    )
}